@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/solid";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/regular";

html {
	background: $black;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family:"swear-display-cilati";
	font-family:"swear-display";
	font-size: 16px;
	line-height: 1;
	scroll-behavior: smooth;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $white;
}

li,ul,p,h1,h2,h3,h4{
	margin: 0;
	line-height: 1.4;
	font-weight: 400;
}

a {
	color: $white;

	@include hover-focus {
		color: $white;
	}
}

.split{
	@include flex(column,initial,initial);
	@include tablet-up{
		@include flex(row,initial,initial);
	}
}

.has-bg{
	position: relative;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.content{
		position: relative;
	}
}

.social{
	@include flex(row,center,center);
	gap: 20px;
	a{
		font-size: 20px;
	}
}

.btn{
	display: inline-block;
	padding: 6px 30px 10px;
	border-radius: 7px;
	border: none;
	background-color: transparent;
	font-size: 18px;
	text-transform: capitalize;
	&.btn-outline-white{
		border: solid 1px $white;
		color: $white;
		@include hover-focus{
			background-color: $white;
			color: $black;
		}
	}
}

header {
	border-bottom: solid 1px $white;
	padding: 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	.container{
		padding: 0;
		.menu-trigger{
			text-transform: uppercase;
			font-size: 30px;
			display: block;
			background-color: transparent;
			padding: 10px;
			color: $white;
			border: none;
			width: 100%;
		}
		.menu{
			list-style: none;
			padding: 0;
			display: none;
			li{
				a{
					text-transform: uppercase;
					font-size: 20px;
					text-align: center;
					display: block;
					padding: 15px;
					@include hover-focus{
						background-color: $white;
						color: $black;
					}
				}
			}
		}
	}

	@include tablet-up{
		.container{
			max-width: 100%;
			.menu-trigger{
				display: none;
			}
			.menu{
				@include flex(row,space-between,initial);
				display: flex !important;
				li{
					flex: 1;
					a{
						font-size: 30px;
					}
				}
			}
		}
	}
}

main {

	section{

		&#hero{
			.bg{
				height: 50%;
				&.right{
					top: unset;
					bottom: 0;
				}
			}
			.content{
				.container{
					height: 100vh;
					padding-top: 78px;
					padding-bottom: 20px;
					@include flex(column,flex-end,initial);
					gap: 145px;
					.copy{
						h2{
							line-height: 1;
							text-align: center;
							text-transform: uppercase;
							font-size: 100px;
							mix-blend-mode: exclusion;
							span{
								display: block;
							}
						}
					}
					.split{
						.side{
							.social{
								margin-bottom: 20px;
								a{
									@include hover-focus{
										color: $black;
									}
								}
							}
							form{
								overflow: hidden;
								max-width: 300px;
								margin: 0 auto;
								.hidden{
									display: none;
								}
								label{
									position: absolute;
									left: -99999px;
								}
								.form-group{
									@include flex(column,initial,initial);
									gap: 10px;
									input{
										border: solid 1px transparent;
										background-color: transparent;
										border-bottom-color: $white;
										text-align: center;
										color: $white;
										font-size: 18px;
										&::placeholder{
											color: $white;
										}
										@include hover-focus{
											outline: none;
											border-color: $white;
										}
									}
								}
							}
							.newsletter-alert{
								text-align: center;
								display: none;
							}
						}
					}
				}
			}

			@include tablet-up{
				overflow: hidden;
				.bg{
					width: 50%;
					height: 100%;
					transition: all .4s;
					&.left{
						left: 0;
					}
					&.right{
						width: 100%;
						left: unset;
						right: -50%;
					}
				}
				@include hover-focus{
					.bg{
						&.right{
							right: -25%;
						}
					}
				}
				.content{
					.container{
						max-width: 1600px;
						padding-top: 83px;
						.copy{
							position: absolute;
							top: calc(50% - 150px);
							left: calc(50% - 114px);
						}
						.split{
							justify-content: space-between;
							align-items: flex-end;
							.side{
								form{
									max-width: max-content;
									.form-group{
										@include flex(row,flex-end,initial);
									}
								}
								.newsletter-alert{
									text-align: right;
								}
							}
						}
					}
				}
			}
		}

		&#music{
			padding: 40px 0;
			overflow: hidden;
			.container{
				max-width: 100%;
				padding: 0;
				h2{
					font-size: 30px;
					padding: 0 15px;
					text-transform: uppercase;
					max-width: 1600px;
					margin: 0 auto 40px;
				}
				.btn{
					display: block;
					max-width: max-content;
					margin: 30px auto 0;
				}
				.slider-music{
					.music-item{
						border: solid 1px $white;
						img{
							width: 100%;
							display: block;
						}
						p{
							text-align: center;
							padding: 10px;
							font-size: 20px;
							border-bottom: solid 1px $white;
							line-height: 1;
						}
						.buttons{
							a{
								display: block;
								font-size: 20px;
								text-align: center;
								padding: 10px;
								border-bottom: solid 1px $white;
								@include hover-focus{
									background-color: $white;
									color: $black;
								}
							}
						}
					}
					.slick-slide {
						margin-left: 20px;
						.slick-list {
							margin-left: -20px;
						}
					}
				}
			}
			@include tablet-up{
				padding: 80px 0;
				.container{
					h2{
						font-size: 45px;
					}
					.slider-music{
						.music-item{
							p{
								font-size: 45px;
								text-align: left;
								padding: 15px 15px 20px;
							}
							.buttons{
								@include flex(row,initial,initial);
								a{
									flex: 1;
									padding: 30px;
									font-size: 18px;
									border: none;
									&:first-child{
										border-right: 1px solid $white;
									}
								}
							}
						}
					}
				}
			}
		}

		&#tour{
			padding: 40px 0;
			.container{
				max-width: 1600px;
				h2{
					font-size: 30px;
					margin-bottom: 40px;
					text-transform: uppercase;
					text-align: center;
				}
				>.btn{
					display: block;
					max-width: max-content;
					margin: 40px auto 0;
				}
				#events{
					display: grid;
					gap: 40px;
					.event{
						font-size: 18px;
						line-height: 1.4;
						.event-date{
							margin-bottom: 10px;
							text-transform: uppercase;
						}
						.event-venue{
							margin-bottom: 10px;
						}
						&:nth-child(n + 7){
							display: none;
						}
					}
					&.all{
						.event{
							&:nth-child(n + 7){
								display: block;
							}
						}
					}
				}
			}
			@include tablet-up{
				padding: 80px 0;
				.container{
					h2{
						font-size: 45px;
					}
					#events{
						grid-template-columns: 1fr 1fr;
						gap: 60px;
						justify-items: center;
						.event{
							@include flex(row,center,center);
							gap: 60px;
							max-width: max-content;
							.event-date,.event-venue{
								margin-bottom: 0;
							}
						}
						&.all{
							.event{
								&:nth-child(n + 7){
									display: flex;
								}
							}
						}
					}
				}
			}
		}

	}

}

footer {
	padding: 40px 0;
	.container{
		max-width: 1600px;
		@include flex(column-reverse,center,center);
		text-align: center;
		gap: 30px;
		.copyright{
			img{
				height: 20px;
				margin: 0 auto 5px;
			}
			p{
				font-size: 8px;
				line-height: 1.6;
				font-family: Arial, Helvetica, sans-serif;
				a{
					border-bottom: solid 1px transparent;
					transition: all .4s;
					@include hover-focus{
						border-bottom-color: $white;
					}
				}
			}
		}
	}

	@include tablet-up{
		.container{
			@include flex(row,space-between,flex-end);
			text-align: left;
			.copyright{
				img{
					margin: 0 0 5px;
				}
			}
		}
	}
}